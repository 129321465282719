import React from 'react'
// import Menu from './Menu'
import { Link } from 'gatsby'
import Container from './Container'

const Header = () => {
  return (
    <Container>
      <div className="Header">
        <Link className="Header__logo" to="/">
          <svg viewBox="0 0 293.7512992658764 293.7512992658764">
            <polygon
              fill="#4051BF"
              points="147,0,152.4563669638858,100.31779718612867,180.9005329991387,3.9624040247659025,163.07494673630643,102.83444682306231,212.97347948946793,15.636001872458394,172.82692196932788,107.73207286359198,241.48977862392127,34.39146686151024,181.1865611539333,114.74664302016953,264.91210933499144,59.21768701969043,187.7031939778686,123.5,281.97776771140025,88.77627439224393,192.02550707882799,133.52024806257876,291.76673969279454,121.47371788296122,193.9204834387496,144.26719304120763,293.7512992658764,155.54728984983993,193.28596439157377,155.16146435034574,287.8244583103768,189.16007520853026,190.15615702337288,165.61574900384036,274.3057343563125,220.49999999999997,184.69979005948707,175.06645381003094,253.92392531123818,247.8775207667038,177.21101765526737,183.00408882659195,227.77781977640853,269.81670827770165,168.09356146942173,189.00073409520036,197.27696106887333,285.13481525552857,157.8389459248947,192.7331089172517,164.06565837640886,293.0060385880656,147,194,129.93434162359117,293.0060385880656,136.1610540751053,192.7331089172517,96.72303893112671,285.13481525552857,125.90643853057828,189.0007340952004,66.22218022359152,269.81670827770165,116.78898234473266,183.00408882659198,40.076074688761835,247.87752076670384,109.30020994051294,175.06645381003096,19.694265643687544,220.50000000000006,103.84384297662712,165.61574900384036,6.175541689623145,189.16007520853032,100.71403560842623,155.16146435034574,0.24870073412358806,155.54728984984,100.07951656125039,144.26719304120763,2.2332603072053985,121.4737178829613,101.97449292117201,133.52024806257876,12.022232288599696,88.77627439224399,106.29680602213139,123.5,29.087890665008544,59.21768701969049,112.81343884606672,114.74664302016951,52.510221376078675,34.39146686151027,121.17307803067212,107.73207286359198,81.02652051053202,15.636001872458422,130.92505326369357,102.83444682306231,113.09946700086125,3.9624040247659025,141.5436330361142,100.31779718612867"
            />
          </svg>
        </Link>
        {/* <Menu /> */}
      </div>
    </Container>
  )
}

export default Header
