import React, { Fragment } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import LinkResolver from './LinkResolver'
import { CapitalizeFirstLetter } from '../utils/helpers'
// import Newsletter from './Newsletter'
import Marquee from './MarqueeString'
import Section from './Section'
import Container from './Container'

const Footer = () => {
  const data = useStaticQuery(query)
  const privacy = data?.sanitySiteSettings?._rawPrivacypage
  const social = data?.sanityCompanyInfo?._rawSocial
  const mailchimp = data?.sanityCompanyInfo?.mailchimp
  const allWords = data?.allSanityWords?.nodes
  const goodWords = allWords.filter(word => word.isGood === 'good')
  const badWords = allWords.filter(word => word.isGood === 'bad')
  const words = goodWords.reduce((res, word, i) => {
    res.push(word)
    if (badWords[i]) {
      res.push(badWords[i])
    }
    return res
  }, [])

  return (
    <footer className="Footer">
      <Section title="Follow us" noMargin className="Footer__section">
        <Container>
          {social &&
            Object.keys(social).map(
              (key, i) =>
                social[key].includes('http') && (
                  <Fragment key={`social-${social[key]}`}>
                    <LinkResolver data={social[key]}>
                      {CapitalizeFirstLetter(key)}
                    </LinkResolver>
                    {i === Object.keys(social).length - 2 && ' and '}
                    {i !== Object.keys(social).length - 2 &&
                      i !== Object.keys(social).length - 1 && (
                        <span>, &nbsp;</span>
                      )}
                  </Fragment>
                )
            )}
          .
          <p>
            Or subscribe to our{' '}
            <LinkResolver data={mailchimp}>newsletter</LinkResolver>
          </p>
        </Container>
      </Section>
      <div className="Footer__meta">
        <p>
          <LinkResolver className="serif text-small" data={privacy}>
            Privacy
          </LinkResolver>
        </p>
      </div>
      <Marquee>
        {words.map((word, i) => (
          <React.Fragment key={`${word}-${i}`}>
            <span className={word.isGood === 'bad' ? 'serif' : 'sans'}>
              {word.word}
            </span>
            &nbsp;·&nbsp;
          </React.Fragment>
        ))}
      </Marquee>
    </footer>
  )
}

export default Footer

export const query = graphql`
  {
    sanityCompanyInfo {
      _rawSocial
      mailchimp
    }
    sanitySiteSettings(_id: { eq: "siteSettings" }) {
      _rawPrivacypage(resolveReferences: { maxDepth: 10 })
    }
    allSanityWords {
      nodes {
        isGood
        word
      }
    }
  }
`
