import React from 'react'
import cc from 'classcat'

const Section = ({ title, children, noMargin, className }) => {
  return (
    <div
      className={cc({
        Section: true,
        'Section--margin': !noMargin,
        [className]: className
      })}
    >
      {title && <h3 className="Section__title">{title}</h3>}
      {children && <div className="Section__content">{children}</div>}
    </div>
  )
}

export default Section
