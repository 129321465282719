import React, { useContext } from 'react'
import cc from 'classcat'

import { getUrl } from '../../../web/heydays-config'

import AppContext from './context/AppContext'
import Header from './Header'
import Footer from './Footer'
import SEO from './SEO'

const Layout = ({ path, children, noHeader, ...props }) => {
  const { state } = useContext(AppContext)
  return (
    <>
      <SEO
        getUrl={getUrl}
        type={props._type}
        slug={props._rawSlug?.current || ''}
        seo={props.seo}
        title={props.title}
      />
      <div className={cc({ Page: true, 'Page--is-dark': state?.isPlaying })}>
        {!noHeader && <Header />}
        <div className="Page__content">{children}</div>
        <Footer />
      </div>
    </>
  )
}

export default Layout
