import React, { useState, useEffect, useRef } from 'react'

import { setOverflowHidden } from '../utils/helpers'
import Portal from './Portal'
import Button from './Button'

export default function Modal({
  buttonType,
  children,
  hideClose,
  contentMaxWidth,
  link,
  backdrop,
  animationWrapper,
  triggerClassName,
  trigger,
  onClick
}) {
  const [open, setOpen] = useState(false)
  const content = useRef(null)

  useEffect(() => {
    setOverflowHidden(open)
  }, [open])

  const outsideClick = event => {
    if (content && content.current && !content.current.contains(event.target)) {
      setOpen(false)
    }
  }

  const Trigger = link ? 'a' : Button
  const TriggerProps = link
    ? {}
    : { variant: buttonType ? buttonType : 'primary' }

  // Bring your own animation handling component
  const AnimationWrapper = animationWrapper ? animationWrapper : 'div'

  return (
    <>
      <Trigger
        className={triggerClassName}
        {...TriggerProps}
        onClick={() => {
          setOpen(!open)
          onClick()
        }}
      >
        {trigger}
      </Trigger>
      <Portal>
        <AnimationWrapper
          trigger={open}
          style={animationWrapper ? {} : { display: open ? 'block' : 'none' }}
        >
          <div
            onClick={e => {
              outsideClick(e)
              onClick()
            }}
            className="Modal"
          >
            <div
              className="Modal__backdrop"
              style={backdrop && { backgroundColor: backdrop }}
            />
            <div className="Modal__container">
              <div
                ref={content}
                style={
                  contentMaxWidth && {
                    maxWidth: contentMaxWidth,
                    width: '100%'
                  }
                }
                className="Modal__content"
              >
                {children({
                  close: () => setOpen(false),
                  isOpen: open,
                  toggleOpen: (state = true) => setOpen(state)
                })}
              </div>
            </div>
            {!hideClose && (
              <Button
                variant="link"
                className="Modal__close"
                onClick={() => setOpen(false)}
              >
                Close
              </Button>
            )}
          </div>
        </AnimationWrapper>
      </Portal>
    </>
  )
}
